<template>
  <div>
    <v-expansion-panels accordion v-model="panel">
      <v-expansion-panel expand v-model="panel">
        <v-expansion-panel-header
          v-if="latticeType.includes('spinful')"
          class="font-weight-bold text"
        >
          Spinful Bond Parameters
        </v-expansion-panel-header>
        <v-expansion-panel-header
          v-else-if="latticeType.includes('spins')"
          class="font-weight-bold text"
        >
          Spin Bond Parameters
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-if="latticeType.includes('spinful')">
            <h4 class="text">t-values:</h4>
            <v-data-table
              class="mb-7"
              dense
              :hide-default-footer="true"
              :headers="headers_t"
              :items="getBondsTable"
              :item-class="itemRowBackground"
            >
            </v-data-table>
          </template>
          <template v-if="latticeType.includes('spinful')">
            <h4 class="text">D-values:</h4>
            <v-data-table
              class="mb-7"
              dense
              :hide-default-footer="true"
              :headers="headers_D"
              :items="getBondsTable"
              :item-class="itemRowBackground"
            >
            </v-data-table>
          </template>

          <template>
            <h4 class="text">J-values:</h4>
            <v-data-table
              dense
              :hide-default-footer="true"
              :headers="headers_J"
              :items="getBondsTable"
              :item-class="itemRowBackground"
            >
            </v-data-table>
          </template>

          <v-row class="mt-3">
            <v-spacer></v-spacer>
            <JValuesDialog></JValuesDialog>
            <InfoDialog info="jValuess"></InfoDialog>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
const InfoDialog = () =>
  import(/* webpackChunkName: "dialogs" */ '../InfoDialog.vue')
const JValuesDialog = () =>
  import(/* webpackChunkName: "dialogs" */ '../JValuesDialog.vue')
import { createNamespacedHelpers } from 'vuex'

const { /* mapMutations, */ mapGetters, mapState } = createNamespacedHelpers(
  'lattice'
)

export default {
  components: {
    JValuesDialog,
    InfoDialog,
  },

  data: () => ({
    panel: 0,
  }),

  methods: {
    itemRowBackground: function (item) {
      if (item.id % 2) {
        return ['white']
      } else {
        return ['blue lighten-5']
      }
    },
  },

  computed: {
    ...mapGetters(['getBondsTable']),
    ...mapState({
      latticeType: (state) => state.latticeType,
    }),

    headers_t() {
      let headers = [
        {
          text: 'From',
          sortable: false,
          value: 'id_from',
          divider: true,
          width: '10%',
        },
        {
          text: 'To',
          sortable: false,
          value: 'id_to',
          divider: true,
          width: '10%',
        },
        {
          text: '↑↑',
          sortable: false,
          value: 't_uu',
          divider: true,
          width: '20%',
        },
        {
          text: '↓↓',
          sortable: false,
          value: 't_dd',
          divider: true,
          width: '20%',
        },
        {
          text: '↑↓',
          sortable: false,
          value: 't_ud',
          divider: true,
          width: '20%',
        },
        {
          text: '↓↑',
          sortable: false,
          value: 't_du',
          width: '20%',
        },
      ]
      return headers
    },

    headers_D() {
      let headers = [
        {
          text: 'From',
          sortable: false,
          value: 'id_from',
          divider: true,
          width: '10%',
        },
        {
          text: 'To',
          sortable: false,
          value: 'id_to',
          divider: true,
          width: '10%',
        },
        {
          text: '↑↑',
          sortable: false,
          value: 'D_uu',
          divider: true,
          width: '20%',
        },
        {
          text: '↓↓',
          sortable: false,
          value: 'D_dd',
          divider: true,
          width: '20%',
        },
        {
          text: '↑↓',
          sortable: false,
          value: 'D_ud',
          divider: true,
          width: '20%',
        },
        {
          text: '↓↑',
          sortable: false,
          value: 'D_du',
          width: '20%',
          cellClass: 'col',
          class: 'col',
        },
      ]
      return headers
    },

    headers_J() {
      let headers = [
        {
          text: 'to',
          sortable: false,
          value: 'id_to',
          divider: true,
          width: '10%',
        },
        {
          text: 'from',
          sortable: false,
          value: 'id_from',
          divider: true,
          width: '10%',
        },
        {
          text: 'Jz',
          sortable: false,
          value: 'Jz',
          divider: true,
          width: '20%',
        },
        {
          text: 'Jperp',
          sortable: false,
          value: 'Jperp',
          divider: true,
          width: '20%',
        },
        {
          text: 'Jcross',
          sortable: false,
          value: 'Jcross',
          divider: true,
          width: '20%',
        },
        { text: 'Jd', sortable: false, value: 'Jd', width: '20%' },
      ]
      return headers
    },
  },
}
</script>

<style scoped>
.row-color {
  color: blue !important;
}

.v-expansion-panel-header {
  padding-right: -5px !important;
  padding-left: -5px !important;
  white-space: nowrap;
}
</style>
